import React, { useEffect, useState } from 'react';
import { Card } from '@app/components/common/Card/Card';
import { Col, Row, Typography, Space, Image, Modal, Avatar } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Button } from '@app/components/common/buttons/Button/Button';
import { getFeedbacks, postFeedbackResponse } from '@app/api/admin.api';
import Text from 'antd/es/typography/Text';
import Title from 'antd/es/typography/Title';
import { Feedback } from '@app/pages/Feedback/types';
import { getProfilePhoto } from '@app/utils/utils';

const FeedbackPage: React.FC = () => {
  const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [response, setResponse] = useState<string>('');
  const [activeFeedback, setActiveFeedback] = useState<Feedback | undefined>(undefined);

  const subscribeFeedbacks = () => {
    getFeedbacks().then((res: Feedback[]) => {
      setFeedbacks(res);
    });
  };

  const onReply = (fd: Feedback) => {
    setActiveFeedback(fd);
    setModalOpen(true);
  };

  const postResponse = () => {
    if (response != '' && activeFeedback) {
      postFeedbackResponse({
        ...activeFeedback,
        is_read: true,
        response,
      }).then(() => {
        setModalOpen(false);
        // subscribeFeedbacks();
        setFeedbacks(
          feedbacks.map((fd: Feedback) => {
            if (fd.id == activeFeedback.id) {
              return {
                ...activeFeedback,
                is_read: true,
                response,
              };
            }
            return fd;
          }),
        );
      });
    }
  };

  useEffect(() => {
    subscribeFeedbacks();
  }, []);

  useEffect(() => {
    if (!isModalOpen) {
      setResponse('');
    }
  }, [isModalOpen]);

  const renderFeedbackRow = (it: Feedback, id: number) => {
    return (
      <Card style={{ width: '100%', padding: 2, marginBottom: 4 }} key={id} size={'small'}>
        <Row>
          <Col span={2}>
            <Avatar src={getProfilePhoto(it.user.profile_picture)} alt="User" shape="circle" size={60} />
          </Col>
          <Col span={8}>
            <div>
              <Text>{it.user.name}</Text>
            </div>
            <Text type={'secondary'}>{it.user.email}</Text>
          </Col>
          <Col span={12} />
          <Col span={2}>{!it.response && <Button onClick={() => onReply(it)}>Reply</Button>}</Col>
        </Row>
        <Row style={{ marginTop: 4 }}>
          <Col span={24}>
            <Title level={5}>{it.title}</Title>
          </Col>
          <Col span={24}>
            <Text style={{ 'whiteSpace': 'pre-line' }} type={'secondary'}>{it.body}</Text>
          </Col>
        </Row>
        <Row style={{ marginTop: 8 }}>
          <Col span={23} push={1}>
            <Text italic>{it.response}</Text>
          </Col>
        </Row>
      </Card>
    );
  }

  return (
    <Space size={'small'} direction={'vertical'} style={{ display: 'flex' }}>
      <Typography.Title level={3}>Feedback</Typography.Title>
      {feedbacks.map(renderFeedbackRow)}
      <Modal
        title={`Reply to ${activeFeedback?.user.name || ''}'s request`}
        visible={isModalOpen}
        onCancel={() => setModalOpen(false)}
        onOk={postResponse}
      >
        <TextArea rows={6} onChange={(event) => setResponse(event.target.value)}>
          {response}
        </TextArea>
      </Modal>
    </Space>
  );
};

export default FeedbackPage;
