import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserApiPaginationModel, UserListPaginatedModel } from '@app/models/UserModel';
import { getContent, getFeatureRequests, getUsers, getUser } from '@app/api/admin.api';
import { ContentModel } from '@app/models/ContentModel';
import { FeatureRequest } from '@app/pages/FeatureRequests/types';
import { AppVersions } from '@app/models/AppVersionModel';

export interface DataSlice {
  users?: UserListPaginatedModel;
  content?: ContentModel[];
  featureRequests?: FeatureRequest[];
  appVersions?: AppVersions;
}

const initialState: DataSlice = {
  users: { results: [] },
  content: [],
  featureRequests: [],
  appVersions: { ios: null, android: null }
};

export const doGetUsers = createAsyncThunk('data/doGetUsers', async (params: UserApiPaginationModel) =>
  getUsers(params).then((res) => {
    return res;
  }),
);

export const doGetUserById = createAsyncThunk('data/doGetUsers', async (id: string) =>
  getUser(id).then((res) => {
    return res;
  }),
);

export const doGetContent = createAsyncThunk('data/doGetContent', async () =>
  getContent().then((res) => {
    return res;
  }),
);

export const doGetFeatureRequests = createAsyncThunk('data/doGetFeatureRequests', async () =>
  getFeatureRequests().then((res) => {
    return res;
  }),
);

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<UserListPaginatedModel | undefined>) => {
      state.users = action.payload;
    },
    setContent: (state, action: PayloadAction<ContentModel[] | undefined>) => {
      state.content = action.payload;
    },
    setFeatureRequests: (state, action: PayloadAction<FeatureRequest[] | undefined>) => {
      state.featureRequests = action.payload;
    },
    setAppVersions: (state, action: PayloadAction<AppVersions | undefined>) => {
      if (action.payload) {
        const { ios, android } = action.payload;

        state.appVersions = {
          ...state.appVersions, // Preserve existing values
          ...(ios ? { ios: ios } : null), // Update iOS version only if provided
          ...(android ? { android: android } : null), // Update Android version only if provided
        };
      }
    }
  },
});

export const { setUsers, setContent, setFeatureRequests, setAppVersions } = dataSlice.actions;

export default dataSlice.reducer;
