import React, { useEffect, useState } from 'react';
import { Button } from '@app/components/common/buttons/Button/Button';
import { UserApiPaginationModel, UserListModel, UserModel } from '@app/models/UserModel';
import { ColumnsType } from 'antd/es/table';
import { Table } from '@app/components/common/Table/Table';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { doGetUsers, setAppVersions, setUsers } from '@app/store/slices/dataSlice';
import { notificationController } from '@app/controllers/notificationController';
import { Avatar, Card, Col, Divider, Input, Modal, Row, Space, Typography } from 'antd';
import { EditOutlined, InfoOutlined, MailOutlined } from '@ant-design/icons';
import { Card as CommonCard } from '@app/components/common/Card/Card';
import { getModeDisplay, getProfilePhoto, parseUserExtraDetails, secondsToString } from '@app/utils/utils';
import { useNavigate } from 'react-router-dom';
import { getAndroidAppVersion, getIOSAppVersion, updateUser } from '@app/api/admin.api';
import { Switch } from '@app/components/common/Switch/Switch';
import { ProfileInfo } from '@app/components/profile/profileCard/ProfileInfo/ProfileInfo';
import styled from 'styled-components';
import { AppVersions } from '@app/models/AppVersionModel';

const UserInfoModal = ({ userData, appVersions, onClose }: { userData: UserListModel, appVersions: AppVersions, onClose: any }) => {
  if (!userData) { return null }
  const user = parseUserExtraDetails(userData.user);

  const isiOS = user?.platform?.includes("iOS") || user?.platform?.includes("iPhone OS") || user?.platform?.includes("iPadOS");
  const latestAppVersion = isiOS ? appVersions?.ios?.trim() || "" : appVersions?.android?.trim() || "";
  const userAppVersion = user?.app_version?.split(" ")?.[0]?.trim() || ""; // Extract the version part (e.g., "2.2")
  const isAppLatestVersion = latestAppVersion && userAppVersion ? userAppVersion === latestAppVersion : false;

  return <Modal
    visible={user !== null}
    onCancel={() => onClose()}
    footer={null}
  >
    <ProfileInfo profileData={user} />
    <div style={{ display: 'flex', flexDirection: 'column', margin: '0 4%' }}>
      <Row>
        <Col span={12}><b>Age</b></Col>
        <Col span={12} style={{ textAlign: 'right' }}>{user.age} {user.age && "years"}</Col>
      </Row>
      <Divider style={{ margin: '16px 0px', background: '#ffffff33' }} />
      <Row>
        <Col span={12}><b>Height</b></Col>
        <Col span={12} style={{ textAlign: 'right' }}>{user.height}</Col>
      </Row>
      <Divider style={{ margin: '16px 0px', background: '#ffffff33' }} />
      <Row>
        <Col span={12}><b>Weight</b></Col>
        <Col span={12} style={{ textAlign: 'right' }}>{user.weight} {user.weight && "lbs"}</Col>
      </Row>
      <Divider style={{ margin: '16px 0px', background: '#ffffff33' }} />
      <Row>
        <Col span={12}><b>Phone</b></Col>
        <Col span={12} style={{ textAlign: 'right' }}>{user.phone_number}</Col>
      </Row>
      <Divider style={{ margin: '16px 0px', background: '#ffffff33' }} />
      <Row>
        <Col span={12}><b>Gender</b></Col>
        <Col span={12} style={{ textAlign: 'right' }}>{user.gender === 0 ? 'Male' : user.gender === 1 ? 'Female' : 'Other'}</Col>
      </Row>
      <Divider style={{ margin: '16px 0px', background: '#ffffff33' }} />
      <Row>
        <Col span={12}><b>Symptoms</b></Col>
        <Col span={12} style={{ textAlign: 'right' }}>{user.symptoms}</Col>
      </Row>
      <Divider style={{ margin: '16px 0px', background: '#ffffff33' }} />
      <Row>
        <Col span={12}><b>Current Mode</b></Col>
        <Col span={12} style={{ textAlign: 'right' }}>{getModeDisplay(userData.user_mode)}</Col>
      </Row>
      <Divider style={{ margin: '16px 0px', background: '#ffffff33' }} />
      <Row>
        <Col span={12}><b>Total Sitting Time</b></Col>
        <Col span={12} style={{ textAlign: 'right' }}>{userData.time_spent > 0 ? secondsToString(userData.time_spent) : '0 hrs'}</Col>
      </Row>
      <Divider style={{ margin: '16px 0px', background: '#ffffff33' }} />
      <Row>
        <Col span={12}><b>Cushion Name</b></Col>
        <Col span={12} style={{ textAlign: 'right' }}>{user.cushion_name}</Col>
      </Row>
      <Divider style={{ margin: '16px 0px', background: '#ffffff33' }} />
      <Row>
        <Col span={12}><b>Firmware Version</b></Col>
        <Col span={12} style={{ textAlign: 'right' }}>{user.firmware_number}</Col>
      </Row>
      <Divider style={{ margin: '16px 0px', background: '#ffffff33' }} />
      <Row>
        <Col span={12}><b>App Version</b></Col>
        <Col span={12} style={{ textAlign: 'right', color: !latestAppVersion ? 'white' : isAppLatestVersion ? 'green' : 'red' }}>{user.app_version}</Col>
      </Row>
      <Divider style={{ margin: '16px 0px', background: '#ffffff33' }} />
      <Row>
        <Col span={12}><b>Platform</b></Col>
        <Col span={12} style={{ textAlign: 'right' }}>{user.platform}</Col>
      </Row>
      <Divider style={{ margin: '16px 0px', background: '#ffffff33' }} />
    </div>
  </Modal>
}

const UsersPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const users = useAppSelector((state) => state.data.users);
  const appVersions = useAppSelector((state) => state.data.appVersions);

  const [isLoading, setLoading] = useState(false);

  const [keyWord, setKeyWord] = useState('')
  const [userForModal, setUserForModal] = useState<UserListModel>(null);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getIOSAppVersion().then(data => {
      const version = data.results[0]?.version;
      dispatch(setAppVersions({ ios: version }))
    })
    getAndroidAppVersion().then(data => {
      const version = data.version
      dispatch(setAppVersions({ android: version }))
    })
  }, [])

  useEffect(() => {
    const DEBOUNCE_DELAY = 300;
    const handler = setTimeout(() => {
      fetchUsers({ page: 1, search: keyWord });
      setCurrentPage(1);
    }, DEBOUNCE_DELAY);

    return () => clearTimeout(handler);
  }, [keyWord]);

  const fetchUsers = (params: UserApiPaginationModel) => {
    setLoading(true);
    dispatch(doGetUsers(params))
      .unwrap()
      .then((res) => {
        dispatch(setUsers(res));
        setLoading(false);
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  const onDeactivate = (isActive: boolean, record: UserModel) => {
    console.log(record);
    const updatedUser = { ...record, is_active: isActive }
    updateUser(updatedUser)
      .then(res => {
        console.log('updateUser', res);
        const updatedUsers = users.results.map(user => (user.user.id === res.id ? { ...user, user: res } : user));
        dispatch(setUsers({ ...users, results: updatedUsers }));
      })
      .catch(error => {
        notificationController.error({ message: error.message });
      })
  }

  const columns: ColumnsType<UserListModel> = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text: string, { user }: UserListModel) => (
        <>
          <Avatar src={getProfilePhoto(user.profile_picture)} alt="User" shape="circle" size={40} />
          <Typography.Text style={{ marginLeft: 14 }}>{user.name}</Typography.Text>
        </>
      ),
    },
    {
      title: 'Email',
      dataIndex: ['user', 'email'],
    },
    {
      title: 'Phone',
      dataIndex: ['user', 'phone_number'],
    },
    {
      title: 'Age',
      dataIndex: 'age',
      render: (text: string, { user }: UserListModel) => (
        <Typography.Text>
          {user.age} {user.age !== null && 'years'}
        </Typography.Text>
      ),
    },
    {
      title: 'Height',
      dataIndex: ['user', 'height'],
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      render: (text: string, { user }: UserListModel) => (
        <Typography.Text>
          {user.weight} {user.weight !== null && 'lbs'}
        </Typography.Text>
      ),
    },
    // {
    //   title: 'Symptoms',
    //   dataIndex: 'symptoms',
    // },
    {
      title: 'Status',
      dataIndex: 'is_active',
      width: 120,
      render: (text: string, { user }: UserListModel) => {
        return (
          <Space>
            <Switch
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              checked={user.is_active}
              onChange={(checked) => onDeactivate(checked, user)}
              style={{ backgroundColor: user.is_active ? 'green' : 'red' }} />
          </Space>
        );
      },
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: '5%',
      render: (text: string, record: UserListModel) => {
        return (
          <Space>
            <Button size="small" type="primary" icon={<InfoOutlined />} onClick={() => setUserForModal(record)} />
            <Button size="small" type="primary" icon={<EditOutlined />} onClick={() => navigate(`edit/${record.user.id}`, { state: { record: record.user } })} />
            <Button size="small" type="primary" icon={<MailOutlined />} onClick={() => navigate(`notify`, { state: { record: record.user } })} />
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: 5, marginBottom: 20 }}>
        <Typography.Title level={3}>
          Users
        </Typography.Title>
        <Input style={{ width: '50%' }} placeholder={'Search by keyword'} value={keyWord} onChange={e => setKeyWord(e.target.value)} />
      </div>
      <CommonCard id="basic-table" padding="1.25rem 1.25rem 0">
        <Table
          columns={columns}
          dataSource={users.results}
          loading={isLoading}
          pagination={{
            showSizeChanger: false,
            current: currentPage,
            pageSize: 10,
            total: users.count,
            onChange: (page) => {
              setCurrentPage(page);
            },
          }}
          onChange={(pagination, filters, sorter) => {
            fetchUsers({ page: pagination.current, search: keyWord });
          }}
          scroll={{ x: 800 }} />
      </CommonCard>
      <UserInfoModal userData={userForModal} appVersions={appVersions} onClose={() => setUserForModal(null)} />
    </>
  );
};

const ProfileCard = styled(Card)`
  height: unset;
`;

export default UsersPage;
