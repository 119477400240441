import { httpApi } from '@app/api/http.api';
import { ContentModel } from '@app/models/ContentModel';
import { UserApiPaginationModel, UserListPaginatedModel, UserModel } from '@app/models/UserModel';
import { FeatureRequest } from '@app/pages/FeatureRequests/types';
import { Feedback } from '@app/pages/Feedback/types';
import qs from 'query-string';
import axios from 'axios';

export interface NotificationPayload {
  heading: string;
  message: string;
}

export const getFeedbacks = (): Promise<Feedback[]> =>
  httpApi.get<Feedback[]>('/api/v1/contact-us/', {}).then(({ data }) => data);

export const postFeedbackResponse = (feedback: Feedback): Promise<Feedback> =>
  httpApi.post<Feedback>(`/api/v1/contact-us/${feedback.id}/response/`, { ...feedback }).then(({ data }) => data);

export const getUsers = (params: UserApiPaginationModel): Promise<UserListPaginatedModel> => {
  const query = qs.stringify(params, { skipNull: true, skipEmptyString: true });
  return httpApi.get<UserListPaginatedModel>(`/api/v1/user/?${query}`, {}).then(({ data }) => data);
}

export const getUser = (id: string): Promise<UserModel> =>
  httpApi.get<UserModel>(`/api/v1/user/${id}/`, {}).then(({ data }) => data);

export const updateUser = (user: UserModel): Promise<UserModel> =>
  httpApi.patch<UserModel>(`/api/v1/user/${user.id}/`, user).then(({ data }) => data);

export const getContent = (): Promise<ContentModel[]> =>
  httpApi.get<ContentModel[]>('/modules/articles/article/').then(({ data }) => data);

export const postContent = (content: any): Promise<ContentModel> =>
  httpApi.post<ContentModel>('/modules/articles/article/', content, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => data);

export const updateContent = (id: number, content: any): Promise<ContentModel> =>
  httpApi.patch<ContentModel>(`/modules/articles/article/${id}/`, content, { headers: { 'Content-Type': 'multipart/form-data' } }).then(({ data }) => data);

export const deleteContent = (id: number): Promise<any> =>
  httpApi.delete<ContentModel[]>(`/modules/articles/article/${id}/`).then(({ data }) => data);

export const postNotification = (notification: NotificationPayload): Promise<Notification> =>
  httpApi.post<Notification>('/api/v1/admin-notifications/', { ...notification }).then(({ data }) => data);

export const getFeatureRequests = (): Promise<FeatureRequest[]> =>
  httpApi.get<FeatureRequest[]>('/api/v1/features/', {}).then(({ data }) => data);

export const updateFeatureRequest = (id: number, data: any): Promise<FeatureRequest> =>
  httpApi.patch<FeatureRequest>(`/api/v1/features/${id}/`, data).then(({ data }) => data);

export const deleteFeatureRequest = (id: number): Promise<any> =>
  httpApi.delete<any>(`/api/v1/features/${id}/`).then(({ data }) => data);

export interface GoogleTokenResponse {
  access_token: string;
}

export const getGoogleAccessToken = (): Promise<GoogleTokenResponse> =>
  httpApi.get('/api/v1/google-token/', {}).then(({ data }) => data);

export const getUsersDemographics = (): Promise<any> =>
  httpApi.get(`/api/v1/users-demographics/`).then(({ data }) => data);

export const getSessionPerScreen = (time_filter: string): Promise<any> =>
  httpApi.get(`/api/v1/session-per-screen/?time_filter=${time_filter}`).then(({ data }) => data);

export const getModeCount = (): Promise<any> =>
  httpApi.get(`/api/v1/mode-count/?start_date=2023-12-06&end_date=2023-12-13`).then(({ data }) => data);

export const getMonthlyActiveUser = (month: number): Promise<any> =>
  httpApi.get(`/api/v1/monthly-active-user/?month=${month}`).then(({ data }) => data);

export const getUserRetention = (time_filter: string): Promise<any> =>
  httpApi.get(`/api/v1/retention-rate/?time_filter=${time_filter}`).then(({ data }) => data);

export const getChurnRate = (time_filter: string): Promise<any> =>
  httpApi.get(`/api/v1/churn-rate/?time_filter=${time_filter}`).then(({ data }) => data);

export const getIOSAppVersion = (): Promise<any> =>
  axios.get('https://itunes.apple.com/lookup?bundleId=com.easecushion.app2').then(({ data }) => data);

export const getAndroidAppVersion = (): Promise<any> =>
  axios.get('https://api.playstore.rajkumaar.co.in/json?id=com.easecushion.app2').then(({ data }) => data);

